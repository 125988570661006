/**
 * TermsOfServiceDialog.js
 *
 * This is a dialog that displays the Terms of Service for the platform.
 * It is displayed when the user is not logged in and has not yet agreed
 * to the Terms of Service. This is a modal dialog that is displayed on top
 * of the underlying page.
 *
 * When the user agrees to Terms of Service, a flag is set in the browser
 * Local Storage to indicate that the user has agreed to the Terms of Service.
 *
 * If the user accesses a URL on the same domain again, the Terms of Service
 * will not be displayed, as long as the flag is set in local storage.
 */

import React, { useContext, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';

import { UserContext } from '../../providers/UserContext.js';

import {
  checkTermsOfServiceAgreement,
  setTermsOfService,
} from './termsOfService';
import TermsOfServiceDocument from './TermsOfServiceDocument';

const useStyles = makeStyles({
  backDrop: {
    backdropFilter: 'blur(5px)',
    backgroundColor: 'rgba(0,0,30,0.4)',
  },
});

export default function TermsOfServiceDialog() {
  const classes = useStyles();
  const { isUserLoggedOut } = useContext(UserContext);
  const [open, setOpen] = useState(false);
  const history = useHistory();

  // Do not show 利用規約 (terms of service) for certain URL paths
  const checkSkipTermsURL = pathname => {
    // returns true if this URL should skip the display of Terms of Service
    const skipTermsPaths = [
      '/',
      '/pw-reset',
      '/signup',
      '/resend-email',
      '/login',
    ];
    // Add exception for Universal Viewer
    const skipTermsPath = skipTermsPaths.includes(pathname)
      || pathname.startsWith('/universal-viewer')
      // No exception for Conference pages, other than event pages
      || pathname.startsWith('/conference/eventpage/');
      // || window.location.hostname.startsWith('conference.');

    if (skipTermsPath) {
      console.debug('Skip TermsOfServiceDialog for', pathname);
    }
    return skipTermsPath;
  };

  useEffect(() => {
    if (
      /**
       * Show TermsOfServiceDialog if:
       * 1) URL is not in the list of paths where TermsOfServiceDialog is skipped
       * 2) users is not logged in
       * 3) user has not yet agreed to TermsOfService (based on a flag in browser Local Storage)
       * Note: when testing with a private browser window, item 3 will be set in local storage in
       * the private window for the duration of the setting. Will need to open a new private window
       * for the change to take effect.
       *  */
      !checkSkipTermsURL(history.location.pathname) &&
      isUserLoggedOut &&
      !checkTermsOfServiceAgreement()
    ) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [history.location.pathname, isUserLoggedOut]);

  const handleClick = () => {
    setTermsOfService(true);
    setOpen(false);
  };
  const handleClose = () => {
    // 何もしない
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="md"
      fullWidth
      BackdropProps={{
        classes: {
          root: classes.backDrop,
        },
      }}
    >
      <DialogTitle>利用規約</DialogTitle>
      <DialogContent>
        <TermsOfServiceDocument />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClick} color="primary" variant="contained">
          利用規約に同意する
        </Button>
      </DialogActions>
    </Dialog>
  );
}
