/**
 * TermsOfServiceDocument.js
 *
 * This component displays the Terms of Service document in an iframe.
 *
 * The Terms document is different for Conference Edition.
 *
 * The document is hosted on Google Docs and is displayed in a modal dialog.
 * This component does not include the dialog itself, or the button to accept
 * the terms, only the iframe content.
 */

import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { isConferencePage } from '../../features/storage/components/Conference/StorageConference';

let TERMS_OF_SERVICE = "https://docs.google.com/document/d/e/2PACX-1vSu7WGkLFfOd-fSeQzRsWGT7PYmNMXhtg2vgjZB4iZGljBCYRjlFhv6NY3DIhoPybCY0af9QslNwPX1/pub";
const TERMS_OF_SERVICE_CONFERENCE = "https://docs.google.com/document/d/e/2PACX-1vR1U1QHAvpgebu3WTk0utp4BvlwvWqE15wrEn0XELI3QiTJGPl7W1UElHBmIy6F0Q/pub";


const useStyles = makeStyles(theme => ({
  wrapper: {
    position: 'relative',
    width: '100%',
    height: 0,
    paddingBottom: props => (props.size == 'large' ? '50vh' : '50vh'),
  },
  iframe: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
  },
}));

export default function TermsOfServiceDocument(props) {
  // size で長さを調整可能に
  const classes = useStyles(props);
  const isConference = isConferencePage();
  // If applicable, use Conference Edition terms
  if (isConference) {
    TERMS_OF_SERVICE = TERMS_OF_SERVICE_CONFERENCE;
  }

  return (
    <div className={classes.wrapper}>
      <iframe
        className={classes.iframe}
        title="s9利用規約"
        src={TERMS_OF_SERVICE}
      ></iframe>
    </div>
  );
}

export { TERMS_OF_SERVICE, TERMS_OF_SERVICE_CONFERENCE };
