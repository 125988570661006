import React, { useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';

import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Divider from '@material-ui/core/Divider';
import CheckOutlinedIcon from '@material-ui/icons/CheckOutlined';

import { useOrgDetail } from '../api/getOrgDetail';
import OrgAvatar from './OrgAvatar';
import { isConferencePage } from '../../storage/components/Conference/StorageConference';

const useStyles = makeStyles(theme => ({
  item: {
    margin: theme.spacing(0, 1),
  },
}));

function OrganizationRow({ org }) {
  const classes = useStyles();
  const history = useHistory();
  const isConference = isConferencePage();
  const { data: orgDetails } = useOrgDetail();

  const [hovered, setHovered] = useState(false);

  const handleOnClick = () => {
    if (orgDetails.uuid != org.uuid) {
      if (isConference) {
        history.push(`/conference/org/${org.uuid}`);
      } else {
        history.push(`/storage/org/${org.uuid}`);
      }
    }
  };

  return (
    <>
      <ListItem
        button
        onClick={handleOnClick}
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
      >
        <ListItemAvatar>
          {org && org.name && <OrgAvatar orgName={org.name} />}
        </ListItemAvatar>
        <ListItemText primary={org.name} className={classes.item} />
        {hovered && orgDetails.uuid != org.uuid && (
          <Typography>開く</Typography>
        )}
        {orgDetails.uuid == org.uuid && <CheckOutlinedIcon />}
      </ListItem>
      <Divider variant="fullWidth" />
    </>
  );
}

OrganizationRow.propTypes = {
  org: PropTypes.object.isRequired,
};

export default OrganizationRow;
