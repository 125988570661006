import React, { useState, useCallback, useContext, useEffect } from 'react';

import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Box } from '@material-ui/core';

import { orgDetailData } from '../../../../api/rest';
import { useAsyncError } from '../../../../api/error';
import { bite2XB } from '../../../../utils/dataProcess';
import { useOrgDetail } from '../../../organization/api/getOrgDetail';

const useStyles = makeStyles(theme => ({
  section: {
    margin: theme.spacing(2, 0),
  },
}));

function OrgDataUsagePage({ permTypeToStatus }) {
  const classes = useStyles();
  const throwError = useAsyncError();
  const { data: orgDetails } = useOrgDetail();

  const [orgDataUsage, setOrgDataUsage] = useState({});

  const fetchOrgDataUsage = useCallback(async () => {
    try {
      const request = await orgDetailData(orgDetails.uuid);
      setOrgDataUsage(request.data);
    } catch (error) {
      throwError(error);
    }
  }, [orgDetails.uuid, throwError]);

  useEffect(() => {
    fetchOrgDataUsage();
  }, [fetchOrgDataUsage]);

  return (
    <>
      <Box className={classes.section}>
        <Typography>組織のデータ使用量</Typography>
        <Typography color="textSecondary">
          「{orgDetails.name}
          」のファイルやフォルダ等の総量や総数を閲覧できます。
        </Typography>
      </Box>

      <Box className={classes.section}>
        {orgDataUsage.file_size >= 0 ? (
          <Typography>
            {`利用中データ:\t${bite2XB(orgDataUsage.file_size, 2)}/100GB`}
          </Typography>
        ) : (
          ''
        )}
      </Box>

      <Box className={classes.section}>
        {orgDataUsage.file_dicom_count >= 0 ? (
          <Typography>{`dicomファイル数:\t${orgDataUsage.file_dicom_count}`}</Typography>
        ) : (
          ''
        )}
      </Box>

      <Box className={classes.section}>
        {orgDataUsage.file_not_dicom_count >= 0 ? (
          <Typography>{`その他のファイル数:\t${orgDataUsage.file_not_dicom_count}`}</Typography>
        ) : (
          ''
        )}
      </Box>

      <Box className={classes.section}>
        {orgDataUsage.folder_count >= 0 ? (
          <Typography>{`フォルダ数:\t${orgDataUsage.folder_count}`}</Typography>
        ) : (
          ''
        )}
      </Box>
    </>
  );
}
OrgDataUsagePage.propTypes = {
  permTypeToStatus: PropTypes.object,
};

export default OrgDataUsagePage;
