/**
 * termsOfService.js
 *
 * Check if the user has agreed to the Terms of Service, by retrieving the value
 * of the flag from local storage.
 */

import { IS_TERMS_OF_SERVICE_AGREE } from '@ohif/viewer/src/utils/localStorageKeys';

function toBoolean(data) {
  return data.toLowerCase() === 'true';
}

export function checkTermsOfServiceAgreement() {
  const isAgree = window.localStorage.getItem(IS_TERMS_OF_SERVICE_AGREE);
  if (isAgree !== null) {
    return toBoolean(isAgree);
  } else {
    return false;
  }
}

export function setTermsOfService(isAgree) {
  window.localStorage.setItem(IS_TERMS_OF_SERVICE_AGREE, isAgree);
}
